let TextUtil = {
    console_log: true,
    info: function (msg, force) {
        if (this.console_log || force) {
            console.log('[TextUtil] ' + msg)
        }
    },
    countMaxTextarea: function () {

        $('[name="description"]').on('change focus keydown keyup', function () {
            var CharsLeft = 0;
            var StrLen    = this.value.length
            if (StrLen === 1 && this.value.substring(0, 1) === " ") {
                this.value = ""
                StrLen       = 0
            }
            if (StrLen > 1000) {
                this.value = this.value.substring(0, 1000)
                CharsLeft    = 0
            } else {
                CharsLeft = 1000 - StrLen
            }
            $('#counter').val(CharsLeft);
        });

    },
}

export {TextUtil};