import {TextUtil} from '../TextUtil';

let setcardEditUtil = {
    console_log: true,
    info: function (msg, force) {
        if (this.console_log || force) {
            console.log('[setcardEditUtil] ' + msg)
        }
    },
    init: function(){
        $('.btn-reset-input').on('click',setcardEditUtil.resetInputField);
        TextUtil.countMaxTextarea();

        $('select[name="category"]').on('change',function(){
            var category = parseInt(this.value);
            if(category === 7){
                $('#termin').show();
                $('#birthday').hide();
            }else{
                $('#birthday').show();
                $('#termin').hide();
            }
        });
    },
    checkVideo: function(ev){
        $('[name="video"]').on('change',setcardEditUtil.onChangeVideo);
        setcardEditUtil.onChangeVideo();
    },

    onChangeVideo: function(){
        let stateAgency = $('[name="video"]').is(':checked');
        if(stateAgency){
            $('[name="video_source"]').prop('required',true);
            $('.video-container').show();
        } else {
            $('.video-container').hide();
            $('[name="video_source"]').prop('required',false);
            $('[name="video_source"]').val('')
        }
    },
    resetInputField: function(ev){
        $(ev.target).closest('.input-group-btn').prev('input').val('');

    }
}

export {setcardEditUtil}