import '../public/lib/countrycode/js/jquery.ccpicker';
let SmsUtil  ={
    countInput: function(arg,element){
        SmsUtil.updateCounter(element);
        $(element).on('input',SmsUtil.updateCounter)
    },
    updateCounter: function(element){
        element = element.currentTarget ?? element;
        let len = $(element).val().length;
        $('.charcounter').text(len);
    },
 ccpicker: function(){
     $("#phoneField").CcPicker({"countryCode":"de",dataUrl:"/lib/countrycode/data.json"});
     $("#phoneField").keyup(function () {

         var test = /\D/;
         if (test.test($(this).val())) {
             $("#phoneField").val($(this).val().replace(/\D/g, ""));
         }
     });
 },
    checkInput: function() {
        $("#code").keyup(function () {

            var test = /\D/;
            if (test.test($(this).val())) {
                $("#phoneField").val($(this).val().replace(/\D/g, ""));
            }
        });
        $(".vcode").keyup(function () {
            if (this.value.length == this.maxLength) {
                $(this).next('.vcode').focus();
            }
        });

        // Listen to paste on the document
        $('#submit').on('click', function (e) {
            // e.preventDefault();
            let code = '';
            $('.vcode').each(function (i, obj) {
                code += obj.value
            });
            console.log(code);
            $("#codeline").val(code);
            $('#form').submit();
        });
        document.addEventListener("paste", function (e) {
            // if the target is a text input
            if (e.target.type === "text") {
                var data = e.clipboardData.getData('Text');
                // split clipboard text into single characters
                data     = data.split('');
                // find all other text inputs
                [].forEach.call(document.querySelectorAll(".vcode"), (node, index) => {
                    // And set input value to the relative character
                    node.value = data[index];
                });
            }
        });
    }
}

export {SmsUtil}