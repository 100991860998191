var InitHelper = {
    parseArg : function(el,argName) {
        let rawArg = $(el).attr(argName);
        let arg = []; // wieso als array initialisiert?
        if (rawArg) {
            try {
                arg = JSON.parse(rawArg);
            } catch (e) {
                    arg = JSON.parse(JSON.stringify(rawArg));
                    console.error("Error parsing init arg: " + arg + " for call on " + $(el).attr('class'), rawArg);
            }
        }
        return arg;
    },
    getInitArg : function(el) {
        return InitHelper.parseArg(el,'on-init-arg');
    }
};

export { InitHelper };