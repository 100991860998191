import { FancyUtil } from './FancyUtil' ;
import { InfoUtil } from './infoUtil';
let SetcardUtil = {
    console_log: true,

    init: function () {
        SetcardUtil.eventListener();
        FancyUtil.init();

    },

    info: function (msg, force) {
        if (this.console_log || force) {
            console.log('[SetcardUtil] ' + msg)
        }
    },
    changeSendMessageInfo: function(ev){

        var sendMessageInfo = $(ev.currentTarget).is(':checked') ? 1:0;
        const data = {
            action: 'update_send_message_info',
            send_message_info: sendMessageInfo,
            id : $('[name="setcardId"]').val()
        };
        $.ajax({
            beforeSend: function(request) {
                request.setRequestHeader("Authorization", env_config.TOKEN);
            },
            type: 'POST',
            dataType: "json",
            url: '/account/index.php',
            data: data,
            success: function (result) {
                InfoUtil.showSuccess(result.message);
            },
            error: function (xhr, status, error){
                var error = '';
                if(undefined === xhr.responseJSON.error){
                    error = 'Es ist ein Fehler aufgetreten';
                }else{
                    error = xhr.responseJSON.error;
                }
                InfoUtil.showError(error);

            }
        })
    },
    eventListener: function(){
        $('[name="send_message_info"]').on('change',SetcardUtil.changeSendMessageInfo);
        $('.masterTooltip').hover(function(){
            // Hover over code
            var title = $(this).attr('title');
            $(this).data('tipText', title).removeAttr('title');
            $('<p class="mtooltip"></p>')
                .text(title)
                .appendTo('body')
                .fadeIn('slow');
        }, function() {
            // Hover out code
            $(this).attr('title', $(this).data('tipText'));
            $('.mtooltip').remove();
        }).mousemove(function(e) {
            var mousex = e.pageX + 20; //Get X coordinates
            var mousey = e.pageY + 10; //Get Y coordinates
            $('.mtooltip')
                .css({ top: mousey, left: mousex })
        });
    }

}

export { SetcardUtil}





