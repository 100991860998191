'use strict';

import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import "jquery-sortablejs";

let FileUploaderUtil = {
    DropzoneFiletransfer:   '/account/filetransfer.php',
    console_log: true,
    init: function () {
        FileUploaderUtil.info('init()');
        Dropzone.autoDiscover = false;

    },

    info: function (msg, force) {
        if (this.console_log || force) {
            console.log('[FileUploaderUtil] ' + msg);
        }
    },
initSortable: function(dropzone){
    $('#upload_images').sortable({
        items: '.dz-preview',
        cursor: 'move',
        opacity: 0.5,
        containment: '.dropzone',
        distance: 20,
        tolerance: 'pointer',
        stop: function () {
            var queue = dropzone.files;
            var newQueue = [];
            $('.dropzone .dz-preview .dz-filename [data-dz-name]').each(function (count, el) {
                var name = el.innerHTML;
                queue.forEach(function (file) {
                    if (file.name === name) {
                        newQueue.push(file);
                    }
                });
            });
            dropzone.files = newQueue;
        },
        update:function(e,ui){
            var current_queue = [];
            dropzone.getFilesWithStatus().forEach(element => {
                current_queue.push(element);
            });

            // dropzone.removeAllFiles();

            for(var i=0;i<current_queue.length;i++){
                dropzone.addFile(current_queue[i]);
            }

            dropzone.processQueue();
            console.log("UIHH");
        }
    });
},
    initDropzones: function (arg,element) {
        Dropzone.autoDiscover = false;
        let container = '';
        if(undefined === arg.container){
            container = 'div#upload_images';
        }else{
            container = arg.container
        }
        Array.prototype.slice.call(document.querySelectorAll(container))
             .forEach( function(element) {

                 if (element.dropzone) {
                     element.dropzone.destroy();
                 }
                 var thisDropzone = new Dropzone(element, {
                     paramName:                  'file',
                     method:                     'POST',
                     headers:                    { 'Authorization': env_config.TOKEN },
                     url:                        FileUploaderUtil.DropzoneFiletransfer,
                     autoProcessQueue:           true,
                     addRemoveLinks:             true,
                     maxFiles:                   env_config.MAX_IMAGES,
                     maxFilesize:                env_config.MAX_UPLOAD_SIZE,
                     uploadMultiple:             true,
                     acceptedFiles:              '.jpeg,.jpg,.png',
                     dictRemoveFileConfirmation: 'Bist Du sicher ?',
                     // Language Strings
                     dictFileTooBig:            'Die Datei ist zu gross. Es sind max. '+env_config.MAX_UPLOAD_SIZE+' kb erlaubt.',
                     dictInvalidFileType:       'Nicht zulässige Datei',
                     dictCancelUpload:          'Abbrechen',
                     dictRemoveFile:            'Löschen',
                     dictMaxFilesExceeded:      'Es sind max. '+env_config.MAX_IMAGES+' Bilder erlaubt',
                     dictDefaultMessage:        'Deine Bilder hier ablegen',
                     init: function () {
                         thisDropzone = this;
                         $.ajax({
                             url:     FileUploaderUtil.DropzoneFiletransfer,
                             type:    "POST",
                             headers: { "Authorization": env_config.TOKEN },
                             // headers: {"Authorization": 'test'},
                             timeout: 5000,
                             data:    {
                                 action:     'fetch',
                                 setcard_id: $('input[name="setcard_id"]').val(),
                                 account_id: env_config.ACCOUNT_ID
                             },
                             success: function (mocks) {
                                 $.each(mocks.data, function (key, value) {
                                     var mockFile = {
                                         name:     value.name,
                                         size:     value.size,
                                         uuid:     value.uuid,
                                         accepted: true
                                     };
                                     thisDropzone.emit("addedfile", mockFile);
                                     thisDropzone.options.thumbnail.call(thisDropzone, mockFile, value.thumb_url);
                                     thisDropzone.emit("complete", mockFile);
                                     thisDropzone.files.push(mockFile);
                                 });
                             },
                             error:   function (xhr, status, error) {
                             }
                         });

                         this.on("removedfile", function (file, xhr, formData) {
                             $.ajax({
                                 url:     FileUploaderUtil.DropzoneFiletransfer,
                                 type:    "POST",
                                 headers: {"Authorization": env_config.TOKEN},
                                 // headers: {"Authorization": 'test'},
                                 timeout: 5000,
                                 data: {
                                     action: 'delete',
                                     uuid: file.uuid,
                                     setcard_id: $('input[name="setcard_id"]').val(),
                                     account_id : env_config.ACCOUNT_ID
                                 }
                             })
                         });
                         this.on("sending", function (file, xhr, formData) {
                             // xhr.setRequestHeader("Authorization",env_config.TOKEN);
                             formData.append("action",           'upload');
                             formData.append("uuid",             file.upload.uuid);
                             formData.append("setcard_id",       $('input[name="setcard_id"]').val());
                             formData.append("image_name",       $('input[name="image_name"]').val());
                             formData.append("account_id",       env_config.ACCOUNT_ID);
                         });
                         this.on("success", function (file, response) {

                         });
                         this.on("error", function (file, response) {
                             //Macht die Fehlermeldung direkt sichtbar
                             $(file.previewElement).find('.dz-error-message').css("opacity", 1);
                             setTimeout(function() {
                                 $(file.previewElement).remove();
                             }, 4000);

                         });
                         FileUploaderUtil.initSortable(this);
                     },
                 });
             })



    }
};

export {FileUploaderUtil}