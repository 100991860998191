let RegistrationUtil = {

    checkAgency: function(ev){
        $('[name="agency"]').on('change',RegistrationUtil.onChangeAgency);
        RegistrationUtil.onChangeAgency();
    },

    onChangeAgency: function(){
        let stateAgency = $('[name="agency"]').is(':checked');
        if(stateAgency){
            $('[name="agency_url"]').prop('required',true);
            $('.agency-container').show();
        } else {
            $('.agency-container').hide();
            $('[name="agency_url"]').prop('required',false);
            $('[name="agency_url"]').val('')
        }
    }
}

export {RegistrationUtil}