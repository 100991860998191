import { Adserver} from './adserver';
import { SnowUtil } from './snowUtil';

let AppUtil = {
    console_log: true,

    init: function () {
        AppUtil.info("init()");
        AppUtil.adZoneContainer();
        AppUtil.eventListener();
        // SnowUtil.createSnowflakes();
        // SnowUtil.initSnow();
        $.AppUtil = AppUtil;
    },

    countryHover: function(){
        $("#map")
            .mouseover(function() {
                $(this).attr("src", "/images/Map_Mouseover.png");
            })
            .mouseout(function() {
                $(this).attr("src", "/images/Map_normal.png");
            });
    },
    info: function (msg, force) {
        if (AppUtil.console_log || force) {
            console.log('[AppUtil] ' + msg)
        }
    },

    adZoneContainer: function(){
        $('#11137:visible').html('<ins data-revive-zoneid="97" data-revive-id="1f6a7b5ff5eb7f5373cb482134424a21"></ins>');
        $('#22661:visible').html('<ins data-revive-zoneid="90" data-revive-id="1f6a7b5ff5eb7f5373cb482134424a21"></ins>');
        $('#22145:visible').html('<ins data-revive-zoneid="89" data-revive-id="1f6a7b5ff5eb7f5373cb482134424a21"></ins>');
        Adserver.init();

    },
    redirectToAccountSetcard: function(setcardId=0){
        if(setcardId > 0){
            window.location.href='/account/?action=show&id='+setcardId;
        }
    },
    redirectTo: function(location){
            window.location.href=location;
    },
    deactivateSubmitButton: function(element){
        element.attr('disabled',true);
        element.addClass('spinner')
    },
    activateSubmitButton: function(element){
        element.attr('disabled',false);
        element.removeClass('spinner');
    },
    eventListener: function(){
        $('.btn-top-menu').on('click',AppUtil.toggleMobileMenu);
        $('button.btn[type="submit"]').on('click', function (ev) {
            AppUtil.info('SUBMIT Click registered');
            if($(this).closest('form') && $(this).closest('form')[0].checkValidity()){
                $(this).attr('disabled',true);
                $(this).addClass('spinner');
                $(this).closest('form')[0].submit();
            }
        });
        document.querySelector('input')?.addEventListener('input', function(e) {
            // Entferne Steuerzeichen aus der Eingabe
            e.target.value = e.target.value.replace(/[^\x20-\x7E\x80-\xFF\u0100-\u017F]/g, '');
        });

        var foo = document.getElementById('foo');
        if(undefined !== foo || true) {
            window.onscroll = function () {
                if(foo) {
                    foo.style.display = ((window.pageYOffset || document.documentElement.scrollTop) < 1) ? 'block' : 'none';
                }
            };
        }
    },

    goBackWithRefresh: function(event) {
        if ('referrer' in document) {
            window.location = document.referrer;
        } else {
            window.history.back();
        }
    },
    toggleMobileMenu: function(ev){
        var menu = document.getElementById("slide-menu");
        if(menu.classList.contains('open')) {
            menu.classList.remove('open')
            menu.classList.toggle("show")

        } else {
            menu.classList.add('open')
            menu.classList.toggle("show")

        }
    },
    redirectIfIframe: function(arg,element){
        let location = arg.location;
        let isIframe = false;
        try {
            isIframe = window.self !== window.top;
        } catch (e) {
            isIframe =  true;
        }
        if(isIframe){
            if(location){
                $('body').html('Bitte warten ...')
                window.top.location = location;
            }else{
                $('body').html('Bitte warten ...')
                window.top.location = env_config.URL_ABSOLUTE;
            }

        }
    },
    checkIframe: function(){
        let isIframe = false;
        try {
            isIframe = window.self !== window.top;
        } catch (e) {
            isIframe =  true;
        }
        if(!isIframe){
            $('body').html('Bitte warten ...')
            window.top.location = env_config.URL_ABSOLUTE;
        }
    }
}

export { AppUtil}





