var slick = require('slick-carousel')

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// https://fancyapps.com/fancybox/getting-started/
let FancyUtil = {
    init: function() {
        const imgWidth = $('.responsive .profil img').width() ?? 150;
        const containerWidth = $('.gallery').parent().width();
        const slides = Math.floor(containerWidth / imgWidth);
        $('.responsive').slick({
            infinite: true,
            slidesToShow: slides,
            slidesToScroll:1
        });
        Fancybox.bind('.responsive:not(.slick-slide) [data-fancybox="gallery"]', {
            Carousel: {
                infinite: false,
            },
            Thumbs: false,
            Images: {
                zoom: false,
            },

        });
        Fancybox.bind('a#notify',{
            defaultType: "iframe",
            width: 520,
            height: 550,
            autoSize: false

        });
        Fancybox.bind('a#various',{
            defaultType: "ajax",
            autoSize: false,
            width: 500,
            height: 500,
        });
        Fancybox.bind('a#contact',{
            defaultType: "iframe",
            autoSize: false,
            animated: false,

        });
        Fancybox.bind('a#rating',{
            defaultType: "iframe",
            width: 520,
            height: 470,
            animated: false,
            on: {
                loaded: (fancybox) => {
                    let iframe = $(fancybox.container).find('iframe');
                    $(iframe).attr('sandbox','allow-scripts allow-forms');
                }
            }
        });
        Fancybox.bind('[data-fancybox=""]', {
            on: {
                close : (fancybox) => {
                    $('.fancybox__container').remove();
                }
            }
        });
         // In JQuery Umgebung bereitstellen
        $.fancybox = Fancybox;

    }


}

export { FancyUtil};
