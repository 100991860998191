import { AppUtil } from './appUtil';
import { AccountUtil } from './accountUtil';
import { MessageUtil } from './messageUtil';
import { Top10Util } from './top10Util';
import { FancyUtil } from './FancyUtil';
import { SetcardUtil } from './setcardUtil';
import { TextUtil } from './TextUtil';
import { setcardEditUtil } from './setcard/setcardEditUtil';
import { FileUploaderUtil } from './fileUploaderUtil';
import { SmsUtil } from './smsUtil';
import { FormUtil } from './formUtil';
import { InfoUtil } from './infoUtil';
import { DateUtil } from './dateUtil';
import { SetcardSignupUtil } from './setcard/setcardSignupUtil';
import { RegistrationUtil } from './registrationUtil';



var InitDispatcher = {
    classes : {
        'AppUtil':               AppUtil,
        'AccountUtil':           AccountUtil,
        'MessageUtil':           MessageUtil,
        'Top10Util':             Top10Util,
        'FancyUtil':             FancyUtil,
        'SetcardUtil':           SetcardUtil,
        'TextUtil':              TextUtil,
        'setcardEditUtil':       setcardEditUtil,
        'FileUploaderUtil':      FileUploaderUtil,
        'SmsUtil':               SmsUtil,
        'FormUtil':              FormUtil,
        'InfoUtil':              InfoUtil,
        'DateUtil':              DateUtil,
        'SetcardSignupUtil':     SetcardSignupUtil,
        'RegistrationUtil':      RegistrationUtil
     },

    /**
     * Parst den String und führt die Funktion mit dem übergebenen Argument-Objekt aus
     * @param theMethod
     * @param arg
     * @param element
     */
    dispatch : function (theMethod,arg,element) {
        let parts = theMethod.split('.');
        this.classes[parts[0]][parts[1]](arg,element);
    },

    /**
     * Liefert das geparte Function-Objekt
     * @param theMethod
     * @returns {func}
     */
    getFunc : function(theMethod) {
        let parts = theMethod.split('.');
        let func =  this.classes[parts[0]][parts[1]];
        if(! (typeof func =="function")) {
            func = function() {console.log('no-op')};
        }
        return func;
    }
};


export {InitDispatcher};