import { Fancybox } from '@fancyapps/ui';

let SetcardSignupUtil = {


    init: function(){
        Fancybox.bind("[data-fancybox]",[]);
        $('.btn-reset-input').on('click',SetcardSignupUtil.resetInputField);

    },
    resetInputField: function(ev){
        $(ev.target).closest('.input-group-btn').prev('input').val('');

    }

}
export {SetcardSignupUtil}