let FormUtil = {

    addSpinning: function(element){
        $(element).attr('disabled',true);
        $(element).prepend('<span class="glyphicon gly-main glyphicon-refresh spinning"></span>')
    },

    removeSpinning(element){
        $(element).attr('disabled',false);
        $(element).find('.glyphicon-refresh').remove();
    }
}
export { FormUtil}