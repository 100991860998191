import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import {DataTable} from 'datatables.net-dt';
import { AppUtil } from './appUtil';
import { DateUtil } from './dateUtil';
import { InfoUtil } from './infoUtil';
let MessageUtil = {

    console_log: true,
    tblSent: null,
    tblInbox: null,
    tblDeleted: null,
    tblBlocked: null,
    maxCharacterMessage: 50,
    init: function () {
        MessageUtil.info("init()");
    },

    info: function (msg, force) {
        if (this.console_log || force) {
            console.log('[MessageUtil] ' + msg)
        }
    },
    singleUploadInput: function(arg,element){
        $('input[type=file]').on('change',function(){
            var t = $(this).val();
            var labelText = t.substr(12, t.length);
            $(this).prev().find('label').text(labelText);
        })
    },
    loadMessageBoxes(){
        MessageUtil.dataTableDefault();
        MessageUtil.dataTableSend();
        MessageUtil.dataTableBlocked();
    },
    getNumNewMessages: function(arg,element){
        var data = {
            account_id: env_config.ACCOUNT_ID,
            action: "num_new_messages"
        };
        $.ajax("/account/message.php",{
            type: "POST",
            data: data,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", env_config.TOKEN);
            },
           success: function (response) {
                if(response.success){
                    let num = response.data;
                    if(num > 0){
                        $('.num-unread-messages').text(num);
                        $('.num-unread-messages').show();
                    }
                }

            },
            error: function(err ){
                console.log(err);
            }
    })

    },
    loadNewMessages: function(arg,element){

        var data = {
            account_id: env_config.ACCOUNT_ID,
            action: "new_messages"
        };
        fetch("/account/messageWidget.php",{
            method: "POST",
            headers: {"Content-type": "application/json; charset=UTF-8","Authorization":env_config.TOKEN},
            body: JSON.stringify(data),

        })
            .then(response => response.json())
            .then(function (response) {
                if(response.success){
                    if(response.data.length === 0){
                        $('.message-count').html(0);
                        $('.new-message').remove();
                        $('.no-message').show();
                    }else{
                        $('.message-count').html(response.data.length)
                        $('.no-message').hide();
                        var message_array=[];
                        var message_text='';
                        $.each(response.data, function( idx,message) {
                            message_text = (message.message.length > MessageUtil.maxCharacterMessage)?message.message.substr(0, MessageUtil.maxCharacterMessage)+'...':message.message;
                            message_array.push(
                                '<span class="text-muted">'+DateUtil.parseDatefromMysql(message.created_ts.date,true)+'</span><br>'+message_text+'<br>');
                        })
                        $(element).find('.no-message').after('<p class="pt-10 small">'+message_array.join('<br>')+'</p>')
                    }
                    //
                }
                // var message = `${response.success}`
                // console.log(response.success);
            })
            .catch(err => console.log(err));

    },

    dataTableDefault:function(){
        MessageUtil.tblInbox= $('#dataTableDefault').DataTable(
            {
                dom: "Bfrtip",
                processing: true,
                serverSide: true,
                responsive: true,
                searching: true,
                language: {
                    url: '/lib/datatable/de-DE.json',
                    emptyTable: 'Keine Nachrichten vorhanden',
                    infoFiltered: "",
                    search: ""
                },
                ordering: true,
                columnDefs: [],
                order: [1, 'desc'],
                columns : [
                    {
                        data: "id",
                        visible: false,
                        searchable: false
                    },
                    {
                        data: "created_ts",
                        type: "date"
                    },
                    {
                        data:   'receiver_name',
                        visible: false,
                        searchable: false
                    },
                    {
                        data:   'sender_name',
                        render: function (data, display, row) {
                            if(null === row.sender_setcard_id) {
                                return data;
                            }else{
                                return '<a href="/show/setcard/' + row.sender_setcard_id + '/" target="_blank">' + data + '</a>';
                            }
                        }

                    },

                    {
                        data:   'sender_setcard_id',
                        visible: false,
                        searchable: false

                    },
                    {
                        data:   'receiver_setcard_id',
                        visible: false,
                        searchable: false

                    },
                    {
                        data: 'message',
                        render: function(data){
                            if(null === data){
                                return '';
                            }
                            return (data.length < MessageUtil.maxCharacterMessage)?data: data.substring(0,MessageUtil.maxCharacterMessage)+'...'
                        }
                    },
                    {
                        data: "file",
                        searchable: false,
                        render: function(data){
                            if(data == "1"){
                                return '<span class="glyphicon glyphicon-file"></span>';
                            }
                            return '';
                        }
                    },
                    {
                        data: null,
                        orderable: false,
                        render: function(data){
                                      return '<span title="Nachricht löschen" class="icon-large deletemessage glyphicon glyphicon-trash"></span>';
                                  }

                    }
                ],
                ajax: {
                    data: {
                        action: 'inbox'
                    },
                    url: '/account/datatable/server_processing.php',
                    type: 'POST',
                    headers: {"Authorization": env_config.TOKEN},

                },
                createdRow: function (row, data, dataIndex) {
                    if(data.is_read == "0") {
                        $(row).addClass('unread');
                    }
                },
            }
        )
        $(MessageUtil.tblInbox).DataTable.ext.errMode = function ( settings, helpPage, message ) {
            if(message.message == "Zugriff verweigert"){
                window.location.href='/login.php';
            }
        };
        $('#dialog-delete .btn-close').on('click',(ev)=> {
            Fancybox.close();
        })
        $('#dialog-delete .btn-delete').on('click',(ev)=> {
            const message_id = $('#dialog-delete [name="message_id"]').val();
            $(ev.currentTarget).attr('disabled',true);
            $(ev.currentTarget).prepend('<span class="glyphicon gly-main glyphicon-refresh spinning"></span>&nbsp;')
            const data = {
                message_id: message_id,
                action: 'delete',
                token: env_config.TOKEN,
                account_id: env_config.ACCOUNT_ID
            }
            $.ajax({
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", env_config.TOKEN);
                },
                type: 'POST',
                dataType: "json",
                url: '/account/message.php',
                data: data,
                success: function (result) {
                    MessageUtil.tblInbox.ajax.reload();
                    Fancybox.close();
                    $(ev.currentTarget).attr('disabled',false);
                    $(ev.currentTarget).find('.glyphicon-refresh').remove()
                },
                error: function (xhr, status, error){
                    let error_message = (xhr?.responseJSON?.error) ?? error;
                    Fancybox.close();
                    InfoUtil.showError(error_message );
                    $(ev.currentTarget).attr('disabled',false);
                    $(ev.currentTarget).find('.glyphicon-refresh').remove()
                }
            })


        })
        $('#dataTableDefault').on('click', 'tbody td:not(td:last-of-type)', function(e) {
            const rowData = MessageUtil.tblInbox.row(e.target.closest('tr')).data();
                    Fancybox.show([{
                        type: 'iframe',
                        src  : '/account/messageView.php?messageId='+rowData.id,
                        width: 800,
                        height: 800
                    }]).on('close',function(){
                        MessageUtil.tblInbox.ajax.reload();
                        MessageUtil.tblSent.ajax.reload();
                });

                }
            );

        $('#dataTableDefault').on('click', 'tbody .deletemessage', function(e) {
                const rowData = MessageUtil.tblInbox.row(e.target.closest('tr')).data();
                if(undefined !== rowData.id && (rowData.id * 1) > 0) {
                    $('#dialog-delete [name="message_id"]').val(rowData.id)
                    Fancybox.show([
                        {
                            type:   'inline',
                            src:    '#dialog-delete',
                            width:  400,
                            height: 400
                        }
                    ]);
                }

            }
        );

    },
    dataTableSend:function(){
        console.log("Lade Nachrichtenausgang");
        MessageUtil.tblSent = $('#dataTableSend').DataTable(
            {
                dom: "Bfrtip",
                processing: true,
                serverSide: true,
                responsive: true,
                searching: true,
                language: {
                    url: '/lib/datatable/de-DE.json',
                    emptyTable: 'Keine Nachrichten vorhanden',
                    infoFiltered: "",
                    search: ""
                },
                ordering: true,
                columnDefs: [],
                order: [1, 'desc'],
                columns : [
                    {
                        data: "id",
                        visible: false,
                        searchable: false
                    },
                    {
                        data: "created_ts",
                        type: "date"
                    },
                    {
                        data:   'receiver_name',
                        render: function (data, display, row) {
                            if(null === row.receiver_setcard_id) {
                                return data;
                            }else{
                                return '<a href="/show/setcard/' + row.receiver_setcard_id + '/" target="_blank">' + data + '</a>';
                            }
                        }

                    },
                    {
                        data:   'sender_name',
                        visible: false,
                        searchable: false
                      },
                    {
                        data:   'sender_setcard_id',
                        visible: false,
                        searchable: false
                    },
                    {
                        data:   'receiver_setcard_id',
                        visible: false,
                        searchable: false
                    },
                    {
                        data: 'message',
                        render: function(data){
                            return (data.length < MessageUtil.maxCharacterMessage)?data: data.substring(0,MessageUtil.maxCharacterMessage)+'...'
                        }
                    },
                    {
                        data: "is_read",
                        render: function(data,display,row){
                            if(data == "1"){
                                return '<span class="glyphicon glyphicon-ok"></span>';
                            }
                            return '';
                        }
                    },
                    {
                        data: "file",
                        searchable: false,
                        render: function(data){
                            if(data == "1"){
                                return '<span class="glyphicon glyphicon-file "></span>';
                            }
                            return '';
                        }
                    }
                ],
                ajax: {
                    data: {
                        action: 'sent'
                    },
                    url: '/account/datatable/server_processing.php',
                    type: 'POST',
                    headers: {"Authorization": env_config.TOKEN},

                },
                createdRow: function (row, data, dataIndex) {
                    // if(data.is_read == "0") {
                    //     $(row).addClass('unread');
                    // }
                },
            }
        )
        $(MessageUtil.tblSent).DataTable.ext.errMode = function ( settings, helpPage, message ) {
            if(message.message == "Zugriff verweigert"){
                window.location.href='/login.php';
            }
            console.log(message);
        };
        $('#dataTableSend').on('click', 'tbody td:not(td:last-of-type)', function(e) {
                const rowData = MessageUtil.tblSent.row(e.target.closest('tr')).data();
                Fancybox.show([{
                    type: 'iframe',
                    src  : '/account/messageView.php?messageId='+rowData.id,
                    width: 800,
                    height: 800
                }]).on('close',function(){
                    MessageUtil.tblInbox.ajax.reload();
                    MessageUtil.tblSent.ajax.reload();
                });


        })

    },

    dataTableBlocked:function(){
        console.log("Lade Blockierliste");
        MessageUtil.tblBlocked = $('#dataTableBlocked').DataTable(
            {
                dom: "Bfrtip",
                processing: true,
                serverSide: true,
                responsive: true,
                searching: true,
                language: {
                    url: '/lib/datatable/de-DE.json',
                    emptyTable: 'Keine blockierten User vorhanden',
                    infoFiltered: "",
                    search: ""
                },
                ordering: true,
                order: [3, 'desc'],
                columnDefs: [],
                columns : [
                    {
                        data: "id",
                        visible: false,
                        searchable: false
                    },
                    {
                        data: "blocked_id",
                        visible: false,
                        searchable: false
                    },
                    {
                        data: "name",
                    },
                    {
                        data: "created_ts",
                        type: "date"
                    },

                    {
                        data: null,
                        orderable: false,
                        render: function(data){
                            return '<span class="btn btn-sm btn-default unblock">Blockierung aufheben</span>';
                        }

                    }
                ],
                ajax: {
                    data: {
                        action: 'block'
                    },
                    url: '/account/datatable/server_processing.php',
                    type: 'POST',
                    headers: {"Authorization": env_config.TOKEN},

                }
            }
        )
        $(MessageUtil.tblBlocked).DataTable.ext.errMode = function ( settings, helpPage, message ) {
            if(message.message === "Zugriff verweigert"){
                window.location.href='/login.php';
            }
            console.log(message);
        };
        $('#dataTableBlocked').on('click', 'tbody .unblock', function(e) {
            let rowData = MessageUtil.tblBlocked.row(e.target.closest('tr')).data();
            var data = {
                'mode': 'unblock',
                'accountId': parseInt(env_config.ACCOUNT_ID),
                'blockAccountId':parseInt(rowData.blocked_id)
            };
            $.ajax({
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", env_config.TOKEN);
                },
                type: 'POST',
                dataType: "json",
                url: '/account/blockMessage.php',
                data: data,
                success: function (result) {
                    MessageUtil.tblBlocked.ajax.reload();
                },
                error: function (xhr, status, error){

                }
            })


        })


    },
    loadMessageViewForm: function(){

        $('.submit').on('click', (e) => {
            if ($('.form')[0].checkValidity()){
                let formData = new FormData($('.form')[0]);
                $.ajax({
                    beforeSend: function(request) {
                        request.setRequestHeader("Authorization", env_config.TOKEN);
                    },
                    type: 'POST',
                    dataType: "json",
                    url: '/account/messageResponse.php',
                    processData: false,
                    contentType: false,
                    data: formData,
                    success: function (result) {
                        $('.form').before($(`<div class="response-message alert alert-success">${result.message }</div>`));
                        $('.form').hide();
                    },
                    error: function (xhr, status, error){
                        $('.form').before($(`<div class="response-message alert alert-danger">${xhr.responseJSON.error}</div>`));
                    }
                })
            }else{
                $('.response-message').remove();
                $('.form').before($(`<div class="response-message alert alert-danger">Die Eingabe ist nicht korrekt</div>`));

            }
        });

        $('.block').on('click', (e) => {
            var mode = $(e.target).hasClass('unblock') ? 'unblock':'block';
            var blockAccountId = $(e.target).data('accountId');
            var accountId = env_config.ACCOUNT_ID;
            var data = {
                'mode': mode,
                'accountId':accountId,
                'blockAccountId':blockAccountId
            };
            if(undefined !== blockAccountId){
                $.ajax({
                    beforeSend: function(request) {
                        request.setRequestHeader("Authorization", env_config.TOKEN);
                    },
                    type: 'POST',
                    dataType: "json",
                    url: '/account/blockMessage.php',
                    data: data,
                    success: function (result) {
                        $('.response-message').remove();
                        $('.form').before($(`<div class="response-message alert alert-success">${result.message }</div>`));
                        $('.form').hide();
                       if($('.block').hasClass('unblock')){
                           $('.block').removeClass('unblock')
                           $('.block').removeClass('text-success').addClass('text-danger')
                           $('.block').html('<span class="glyphicon glyphicon-share text-danger"></span>&nbsp;Blockieren');
                       }else{
                           $('.block').addClass('unblock');
                           $('.block').removeClass('text-danger').addClass('text-success')
                           $('.block').html('<span class="glyphicon glyphicon-share"></span>&nbsp;Freigeben');
                       }
                        window.parent.$('#dataTableBlocked').DataTable().ajax.reload()

                    },
                    error: function (xhr, status, error){
                        $('.response-message').remove();
                        $('.form').before($(`<div class="response-message alert alert-danger">${xhr.responseJSON.error}</div>`));
                        $('.form').hide();
                    }
                })
            }

        })


    }

}
export { MessageUtil }