let DateUtil = {

    parseDatefromMysql : function(sqlDateTime,time=false) {
        let dateTimeParts= sqlDateTime.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
        dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one

        const dateObject = new Date(...dateTimeParts); // our Date object
        var day = (dateObject.getDate() <= 9)? '0'+dateObject.getDate():dateObject.getDate();
        var month = (dateObject.getMonth() <= 9)? '0'+dateObject.getMonth():dateObject.getMonth();
        var year = dateObject.getFullYear();
        var hour = (dateObject.getHours() <= 9)? '0'+dateObject.getHours():dateObject.getHours();
        var minutes = (dateObject.getMinutes() <= 9)? '0'+dateObject.getMinutes():dateObject.getMinutes();
        var seconds = (dateObject.getSeconds() <= 9)? '0'+dateObject.getSeconds():dateObject.getSeconds();

        var time = hour+':'+minutes;
        var formated_date = day+'.'+month+'.'+year;
        if(time){
            return formated_date+' '+time
        }
        return formated_date;
    },
}

export { DateUtil}