import * as Toastr from 'toastr'


let  InfoUtil = {
    options: {
        closeButton: true,
        debug: false,
        newestOnTop: true,
        progressBar: false,
        positionClass: "toast-top-right",
        preventDuplicates: false,
        onclick: null,
        showDuration: "300",
        hideDuration: "1000",
        timeOut: 5000,
        extendedTimeOut: 1000,
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut"
    },

    showInfo: function(message,options={}){
        Toastr.options = {...InfoUtil.options,...options};
        Toastr.info(message);
    },
    showWarning: function(message,options={}){
        Toastr.options = {...InfoUtil.options,...options};
        Toastr.info(message,'',{ timeOut: 0, extendedTimeOut:0 });
    },
    showSuccess: function(message,options={}){
        Toastr.options = {...InfoUtil.options,...options};
        Toastr.success(message);
    },
    showError: function(message,options={}){
        Toastr.options = {...InfoUtil.options,...options};
        Toastr.error(message,'Fehler',{ timeOut: 5000, extendedTimeOut:1000 });
    }


}

export { InfoUtil}