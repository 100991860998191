import { InitDispatcher } from './initDispatcher.js';
import { InitHelper } from './initHelper.js';
var InitActions = {

    onInit : () =>  {

        let collection = $('.initializer');
        InitActions.callInitializers(collection);

    },


    onAddElements : (container) => {
        let collection = container.find('.initializer');
        InitActions.callInitializers(collection);
    },




    callInitializers : (collection)  => {
        collection.each( (idx,el) => {
            InitActions.callOneInitialzer(el);
        });
    },

    callOneInitialzer : (el) => {
        let toCall = $(el).attr('on-init-call');
        let arg    = InitHelper.getInitArg(el);

        InitDispatcher.dispatch(toCall,arg,el);
    }
};

export { InitActions };