import { InfoUtil } from './infoUtil';

let AccountUtil = {
    console_log: true,

    init: function () {
        AccountUtil.previewScreeshoot();
        },

    initLeftSetcards: function() {
        $('.search-setcards').on('keyup',AccountUtil.searchLeftSetcards)
        $('.btn-reset-search').on('click',AccountUtil.searchLeftSetcardsReset)
    },
    info: function (msg, force) {
        if (this.console_log || force) {
            console.log('[AccountUtil] ' + msg)
        }
    },
    ready:function(){

    },
    initCustomArea: function(){
        $('.tooltip').tooltip('show').each((i, el)=> $(el).data('bs.tooltip').tip().addClass('blink'));

    },
    searchLeftSetcards: function(ev){
        var input = $(ev.currentTarget).val();
        $(ev.currentTarget).next('.deine-anzeigen').find('li').show();
        if(undefined !== input && input.length > 1){
            $('.setcard-list').find('.deine-anzeigen li:not(:contains('+input+'))').hide();
        }

    },
    searchLeftSetcardsReset: function(ev){
        $('.search-setcards').val('');
        $('.setcard-list .deine-anzeigen').find('li').show();
    },
    previewScreeshoot: function(){
        /* CONFIG */

        var xOffset = 10;
        var yOffset = 30;

        // these 2 variable determine popup's distance from the cursor
        // you might want to adjust to get the right result

        /* END CONFIG */
        $("a.screenshot").hover(function(e){
                this.t = this.title;
                this.title = "";
                var c = (this.t != "") ? "" + this.t : "";
                $("body").append("<p id='screenshot'><img src='"+ this.rel +"' alt='url preview' width='120' height='142' class='img-responsive' />"+ c +"</p>");
                $("#screenshot")
                    .css("top",(e.pageY - xOffset) + "px")
                    .css("left",(e.pageX + yOffset) + "px")
                    .fadeIn("fast");
            },
            function(){
                this.title = this.t;
                $("#screenshot").remove();
            });
        $("a.screenshot").mousemove(function(e){
            $("#screenshot")
                .css("top",(e.pageY - xOffset) + "px")
                .css("left",(e.pageX + yOffset) + "px");
        });
    },
    resendVerificationEmail: function(ev){
        const accountId = parseInt($(ev.target).closest('form').find('[name="temp_account_id"]').val());
        if(accountId > 0 && (undefined === $(ev.target).closest('div').attr('disabled')|| $(ev.target).closest('div').attr('disabled') === false)){
            $.ajax({
                url: '/email_verification.php',
                method: 'POST',
                data: { accountId: accountId,action:'resend_email'},
                success: function(response) {
                    if(response.success === true) {
                        InfoUtil.showSuccess(response.message);
                        $(ev.target).closest('div').attr('disabled',true);
                    }else{
                        InfoUtil.showError(response.message);
                    }
                },
                error: function(error) {
                    InfoUtil.showError('Es ist ein Fehler beim Versand aufgetreten');
                }
            });
        }
    },
    emailVerification: function(){
        $('.btn-repeat').on('click',AccountUtil.resendVerificationEmail);

            $(".vcode").keyup(function () {
                if (this.value.length == this.maxLength) {
                    $(this).parent().next().find('.vcode').focus();
                }
                var unfilled = $(".vcode").filter(function() {
                    return $.trim(this.value) == '';
                });
                if(unfilled.length){
                    $('.btn-verify').attr('disabled',true);
                }else{
                    $('.btn-verify').attr('disabled',false);
                }
            });

            // Listen to paste on the document
            $('#submit').on('click', function (e) {
                // e.preventDefault();
                let code = '';
                $('.vcode').each(function (i, obj) {
                    code += obj.value
                });
                console.log(code);
                $("#codeline").val(code);
                $('#form').submit();
            });
            document.addEventListener("paste", function (e) {
                // if the target is a text input
                if (e.target.type === "text") {
                    var data = e.clipboardData.getData('Text');
                    // split clipboard text into single characters
                    data     = data.split('');
                    // find all other text inputs
                    [].forEach.call(document.querySelectorAll(".vcode"), (node, index) => {
                        // And set input value to the relative character
                        node.value = data[index];
                    });
                }
            });
    },
    getVisitedSetcards: function(ev){
    }
}

export { AccountUtil};