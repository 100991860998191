let SnowUtil = {
	snow_area_container: '.winter',
	snow_flake_count: 1000,
	snow_colors: ['#AAAACC', '#DDDDFF', '#CCCCDD', '#F3F3F3', '#F0FFFF'],
	snow_fonts:      ['Arial Black', 'Arial Narrow', 'Times', 'Comic Sans MS'],
	snow_char:       '*',		// das Zeichen, das als Schneeflocke verwendet wird
	snow_gravity:    0.8,		// wie schnell die Schneeflocken fallen
	snow_max_size:   28,		// die maximale Schriftgrösse einer Schneeflocke
	snow_min_size:   10,		// die minimale Schriftgrösse einer Schneeflocke
	snow_init_delay: 50,		// Verzögerungszeit in Millisekunden, bevor es zu schneien anfängt
	snow_init_time: 0,
	snowflakes: [],
	snow_area_el: null,

	createSnowflakes: function(){
		var style = 'position:absolute; top:-' + SnowUtil.snow_max_size + 'px; z-index:99;';
		for (var i = 0; i <= SnowUtil.snow_flake_count; i++) {
			$('body').append('<sp' + 'an id="snwflk' + i + '" style="' + style + '">' + SnowUtil.snow_char + '</sp' + 'an>');
		}
		SnowUtil.delayedSnow();

	},
	delayedSnow: function(){
		setTimeout(
			function() {
				SnowUtil.initSnow()
			}.bind(SnowUtil)
			,Math.max(50, SnowUtil.snow_init_delay))
	},

	randInt: function(range) {
		return Math.floor(Math.random() * range);
	},

	initSnow: function(){
		SnowUtil.snow_area_el = $(SnowUtil.snow_area_container);
		if(SnowUtil.snow_area_el.length === 0){
			console.log(`Snow Container ${SnowUtil.snow_area_container} nicht gefunden`);
			return false;
		}
		// reapeat until we have the snow_area_el
		if(!SnowUtil.snow_area_el || SnowUtil.snow_area_el.outerWidth <= SnowUtil.snow_max_size || SnowUtil.snow_area_el.outerHeight <= SnowUtil.snow_max_size) {
			// after 5 secs cancel
			if(SnowUtil.snow_init_time < 5000) {
				setTimeout(
					function() {
						SnowUtil.initSnow()
					}.bind(SnowUtil)
					,50)
			}
			SnowUtil.snow_init_time += 50;
		}
		// offest fix
		SnowUtil.snow_area_el.css('position','relative')
		for (var i = 0; i <= SnowUtil.snow_flake_count; i++)
		{
			SnowUtil.snowflakes[i] = document.getElementById('snwflk' + i);
			SnowUtil.snowflakes[i].size = (SnowUtil.randInt(SnowUtil.snow_max_size - SnowUtil.snow_min_size) + SnowUtil.snow_min_size);
			SnowUtil.snowflakes[i].posx = -SnowUtil.snowflakes[i].size;
			SnowUtil.snowflakes[i].posy = -SnowUtil.snowflakes[i].size;
			SnowUtil.snowflakes[i].sink = (SnowUtil.snow_gravity * SnowUtil.snowflakes[i].size / SnowUtil.snow_min_size);
			SnowUtil.snowflakes[i].wobamp = (Math.random() * (SnowUtil.snowflakes[i].size));
			SnowUtil.snowflakes[i].wob = 0.0;
			SnowUtil.snowflakes[i].wobspeed = (0.03 + Math.random() / 10.0);
			SnowUtil.snowflakes[i].style.fontFamily = SnowUtil.snow_fonts[SnowUtil.randInt(SnowUtil.snow_fonts.length)];
			SnowUtil.snowflakes[i].style.fontSize = SnowUtil.snowflakes[i].size + 'px';
			SnowUtil.snowflakes[i].style.color = SnowUtil.snow_colors[SnowUtil.randInt(SnowUtil.snow_colors.length)];
		}
		setInterval(
			function() {
				SnowUtil.updateSnow()
			}.bind(SnowUtil)
			,50)

	},

	updateSnow: function(){
		var bl = SnowUtil.snow_area_el.offset().left;
		var bt = SnowUtil.snow_area_el.offset().top;
		var bw = SnowUtil.snow_area_el.outerWidth();
		var bh = SnowUtil.snow_area_el.outerHeight();
		var br = bl + bw;
		var bb = bt + bh;

		for (var i = 0; i <= SnowUtil.snow_flake_count; i++)
		{
			SnowUtil.snowflakes[i].wob += SnowUtil.snowflakes[i].wobspeed;
			var x = SnowUtil.snowflakes[i].posx + (SnowUtil.snowflakes[i].wobamp * Math.sin(SnowUtil.snowflakes[i].wob));
			SnowUtil.snowflakes[i].posy += SnowUtil.snowflakes[i].sink;
			SnowUtil.snowflakes[i].style.left = Math.round(x) + 'px';
			SnowUtil.snowflakes[i].style.top = Math.round(SnowUtil.snowflakes[i].posy) + 'px';

			var s = SnowUtil.snowflakes[i].size;
			// check bounds
			if (SnowUtil.snowflakes[i].posy > (bb - s) || x < bl || x  > (br - s))
			{
				SnowUtil.snowflakes[i].posx = bl + s + SnowUtil.randInt(bw - (3 * s));
				if(SnowUtil.snowflakes[i].posy < 0)
					SnowUtil.snowflakes[i].posy = bt + SnowUtil.randInt(bh - 2 * s);
				else
					SnowUtil.snowflakes[i].posy = bt;
			}
		}
	}
}
export { SnowUtil};