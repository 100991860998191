require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require('bootstrap')

// import 'bootstrap';

import { InitActions } from './initAction.js';
import { AppUtil } from './appUtil';
$(document).ready(function () {
    //$('#layout-top').css('background-color','red');

    window.$ = $; // FIXME
    InitActions.onInit(); // seiteninitialisierungen
    AppUtil.init();
});
